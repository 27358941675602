import { render, staticRenderFns } from "./BillingHistoryTable.vue?vue&type=template&id=013c200c&scoped=true&"
import script from "./BillingHistoryTable.vue?vue&type=script&lang=js&"
export * from "./BillingHistoryTable.vue?vue&type=script&lang=js&"
import style0 from "./BillingHistoryTable.vue?vue&type=style&index=0&id=013c200c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013c200c",
  null
  
)

export default component.exports