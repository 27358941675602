<template>
  <b-overlay :show="showLoader" rounded="sm">
    <div v-for="(item, index) of items" :key="index" class="justify-content-center
    card-div-wrapper card-div course-info ov-card" :class="getCardClassName(index)"
         :style="{'background': getCardBackgroundColor(), 'color': getTextColor()}">
      <div class="row ov-first-row">
        <div class="col-6 part-v-center">
          <h5 class="m-0">{{ item.name }}</h5>
        </div>
        <div class="col-3 text-center part-v-center">
          <h6 class="m-0">{{ currencySymbol }}{{ item.price }}</h6>
        </div>
        <div class="col-3 text-right">
          <b-button variant="primary" class="btn ov-button" @click="initPayment(item)"
                    :style="{'background': getButtonBackgroundColor(), 'color': getButtonTextColor()}">Buy
          </b-button>
        </div>
      </div>
      <div class="row ov-second-row">
        <div class="col-12" style="font-size: smaller">
          <span>{{ item.description }}</span>
        </div>
      </div>
    </div>
    <div v-if="items.length === 0" class="justify-content-center card-div-wrapper card-div course-info ov-card"
         :class="{'loader-space': !showOnlyAfterDataLoad}"
         :style="{'background': getCardBackgroundColor(), 'color': getTextColor()}">
      <div class="row" v-if="showOnlyAfterDataLoad">
        <div class="col-xl-12 text-center">
          <h5>Add-ons are not available for your current plan.</h5>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    token: {
      type: String
    },
    apiKey: {
      type: String
    },
    mode: {
      type: String
    }
  },
  data() {
    return {
      showLoader: true,
      items: [],
      addOnplanMap: {},
      currencySymbol: null,
      colorSettings: null,
      showOnlyAfterDataLoad: false
    }
  },
  methods: {
    async getCompanyDetails() {
      try {
        if (this.countryId) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
          }
          const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/country/${this.countryId}`
          const {data} = await axios.get(url, {
            headers: headers
          })
          return data.value
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    async getAllAddonPlans() {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        let url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/customer/${this.customerIdentifier}/applicable/addons`
        if (!this.customerIdentifier) {
          url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/addons?status=ACTIVE`
        }
        const {data} = await axios.get(url, {
          headers: headers
        })
        if (data.status) {
          this.items = [];
          const value = data.value
          for (let i = 0; i < value.length; i++) {
            const item = value[i];
            this.items.push(item);
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async initPayment(item) {
      if (!this.customerIdentifier) {
        if (this.workflowSettings.redirect_url_not_login) {
          window.location = this.workflowSettings.redirect_url_not_login
        } else {
          console.error('Redirect url if not logged in not set')
        }
        return
      }
      this.showLoader = true
      const headers = {
        'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
      }
      const payload = {
        company_id: this.companyId,
        country_id: this.countryId,
        customer_identifier: this.customerIdentifier,
        email: this.email,
        addon_id: item.tsid,
        payment_component: 'ADD_ON'
      }
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/payment`, payload, {headers: headers})
        if (data.status) {
          const val = data.value
          const mode = val.subscription_mode
          switch (mode) {
            case 'DIRECT':
              if (this.workflowSettings.redirect_url_after_payment) {
                window.location = this.workflowSettings.redirect_url_after_payment
                window.location.reload(true)
              } else
                console.log('Please configure redirect url')
              break
            case 'PAYMENT':
              window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/${val.transaction_tsid}`
              break
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.showLoader = false
      }
    },
    async getAddOnColorSettings() {
      this.showOverlay = true
      try {
        const headers = {
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        let url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/settings?type=ADDON_COLOR_SETTINGS`
        const {data} = await axios.get(url, {headers: headers})
        if (data.status && data.value && data.value.setting) {
          this.colorSettings = data.value.setting
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.showOverlay = false
      }
    },
    getCardBackgroundColor() {
      if ((!this.mode || this.mode === 'LIGHT') && this.colorSettings?.card_background_color) {
        return this.colorSettings.card_background_color
      }
      if (this.mode === 'DARK' && this.colorSettings?.dark_mode?.card_background_color) {
        return this.colorSettings?.dark_mode?.card_background_color
      }
      return 'inherit'
    },
    getTextColor() {
      if ((!this.mode || this.mode === 'LIGHT') && this.colorSettings?.text_color) {
        return this.colorSettings.text_color
      }
      if (this.mode === 'DARK' && this.colorSettings?.dark_mode?.text_color) {
        return this.colorSettings?.dark_mode?.text_color
      }
      return 'inherit'
    },
    getButtonBackgroundColor() {
      if ((!this.mode || this.mode === 'LIGHT') && this.colorSettings?.button_background_color) {
        return this.colorSettings.button_background_color
      }
      if (this.mode === 'DARK' && this.colorSettings?.dark_mode?.button_background_color) {
        return this.colorSettings?.dark_mode?.button_background_color
      }
      return 'inherit'
    },
    getButtonTextColor() {
      if ((!this.mode || this.mode === 'LIGHT') && this.colorSettings?.button_text_color) {
        return this.colorSettings.button_text_color
      }
      if (this.mode === 'DARK' && this.colorSettings?.dark_mode?.button_text_color) {
        return this.colorSettings?.dark_mode?.button_text_color
      }
      return 'inherit'
    },
    getCardClassName(index) {
      return `ov-card-${index}`;
    }
  },
  async mounted() {
    this.showLoader = true
    await this.getAddOnColorSettings();
    const countryDetails = await this.getCompanyDetails();
    this.currencySymbol = countryDetails.currency_symbol;
    await this.getAllAddonPlans()
    this.showLoader = false
    this.showOnlyAfterDataLoad = true
  }
}
</script>


<style scoped>

.card-div-wrapper {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 12px;
}

.part-v-center {
  display: flex;
  align-items: center;
}

.card-div {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.course {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.course h6 {
  opacity: 0.6;
  margin: 0;
  text-transform: uppercase;
}

.course h2 {
  margin: 10px 0;
}

.course-info {
  padding: 16px;
}

.loader-space {
  padding: 32px;
}


</style>
