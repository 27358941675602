<template>
  <div class="plan-button-wrapper">
    <b-overlay :show="showOverlay" rounded="sm">
      <vue-confirm-dialog group="button_plan"></vue-confirm-dialog>
      <notifications group="button_plan"/>
      <b-button :disabled="showLoader"
                :style="buttonCss"
                variant="info" class="btn" @click="initPayment()">
        {{ label || 'Purchase' }}
        <b-spinner small label="Spinning" v-if="showLoader"></b-spinner>
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import {isFreePlan} from "@/util/planUtil";
import axios from "axios";

export default {
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    apiKey: {
      type: String
    },
    label: {
      type: String
    },
    planTsid: {
      type: String
    },
    planType: {
      type: String
    },
    buttonCss: {
      type: String
    }
  },
  data() {
    return {
      finalWorkflowSettings: {},
      customerDetails: null,
      currencySymbol: null,
      item: null,
      showLoader: false,
      showOverlay: true
    }
  },
  methods: {
    async getWorkflowSettings() {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/settings?type=WORKFLOW_SETTINGS`, {
          headers: headers
        })
        if (data && data.value) {
          this.finalWorkflowSettings = {...data.value.setting}
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getCustomerDetails() {
      try {
        if (this.customerIdentifier) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
          }
          const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/customer/${this.customerIdentifier}`
          const {data} = await axios.get(url, {
            headers: headers
          })
          return data.value
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    async getCountryDetails() {
      try {
        if (this.countryId) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
          }
          const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/country/${this.countryId}`
          const {data} = await axios.get(url, {
            headers: headers
          })
          return data.value
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    async getPlanDetails() {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/plan/${this.planTsid}`
        const {data} = await axios.get(url, {
          headers: headers
        })
        return data.value
      } catch (e) {
        console.error(e)
      }
      return null
    },
    isContactUsPlan() {
      return this.item.is_contact_us_plan
    },
    async initPayment() {
      if (this.isContactUsPlan()) {
        if (!this.workflowSettings.contact_us_url) {
          console.error('Contact Us url is not configured')
          alert('Contact Us url is not configured')
          return
        }
        window.location = this.workflowSettings.contact_us_url
        return
      }

      if (!this.customerIdentifier) {
        if (this.workflowSettings.redirect_url_not_login) {
          window.location = this.workflowSettings.redirect_url_not_login
        } else {
          this.$notify({group: 'button_plan', type: 'error', title: 'Error', text: 'Redirect url is not configured'});
          console.error('Redirect url is not configured')
          alert('Redirect url is not configured')
        }
        return
      }

      // check if user has active subscription then just show yes no
      if (this.customerDetails && this.customerDetails.plan &&
          this.customerDetails.subscription_start_date <= new Date().getTime() &&
          this.customerDetails.subscription_end_date >= new Date().getTime() &&
          isFreePlan(this.item, this.planType)
      ) {
        this.$confirm(
            {
              group : 'button_plan',
              message: 'Are you sure you want to switch your plan? The change will take effect immediately.',
              button: {
                no: 'No',
                yes: 'Yes'
              },
              callback: confirm => {
                if (confirm) {
                  this.actualPayment();
                }
              }
            }
        )
      } else {
        await this.actualPayment();
      }
    },
    async actualPayment() {
      this.showLoader = true
      const headers = {
        'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
      }
      const payload = {
        company_id: this.companyId,
        country_id: this.countryId,
        customer_identifier: this.customerIdentifier,
        plan_id: this.item.tsid,
        plan_type: this.planType,
        payment_component: 'PLAN'
      }
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/payment`, payload, {headers: headers})
        if (data.status) {
          const val = data.value
          const mode = val.subscription_mode
          switch (mode) {
            case 'DIRECT':
              if (this.workflowSettings.redirect_url_after_payment) {
                window.location = this.workflowSettings.redirect_url_after_payment
                window.location.reload(true)
              } else {
                console.log('Redirect url is not configured')
                alert('Redirect url is not configured')
              }
              break
            case 'PAYMENT':
              window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/${val.transaction_tsid}`
              break
          }
        }
      } catch (e) {
        const data = e.response.data
        const message = data?.error?.message || 'Something went wrong. Please try again'
        this.$notify({group: 'button_plan', type: 'error', title: 'Error', text: message});
      } finally {
        this.showLoader = false
      }
    },
  },
  async mounted() {
    this.showOverlay = true
    await this.getWorkflowSettings()
    this.customerDetails = await this.getCustomerDetails();
    const countryDetails = await this.getCountryDetails();
    this.currencySymbol = countryDetails.currency_symbol;
    this.item = await this.getPlanDetails()
    this.showOverlay = false
  }
}
</script>

<style>
.plan-button-wrapper {
  width: fit-content;
}
</style>
