<template>
  <div class="">
    <b-overlay :show="showOverlay" rounded="sm">
      <b-table :busy="isBusy" :fields="fields" :items="items" class="">

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(tsid)="data">
          <p class="text-left text-wrap">
            {{ data.item.tsid.replace('INV-', '') }}
          </p>
        </template>

        <template #cell(name)="data">
          <p class="text-left text-wrap">
            {{ getPlanName(data.item) }}
          </p>
        </template>

        <template #cell(invoice_date)="data">
          <p class="text-left text-wrap">{{ getDateInStr(data.item.invoice_date) }}</p>
        </template>

        <template #cell(due_date)="data">
          <p class="text-left text-wrap">{{
              getDateInStr(data.item.due_date)
            }}</p>
        </template>

        <template #cell(paid_date)="data">
          <p class="text-left text-wrap">{{ getDateInStr(data.item.paid_date) }}</p>
        </template>

        <template #cell(payment_method)="data">
          <p class="text-left text-wrap">
            {{
              data.item.transactions && data.item.transactions.card_last4 && '****' + data.item.transactions.card_last4
            }}
            {{
              data.item.transactions && data.item.transactions.card_network && data.item.transactions.card_network.toUpperCase()
            }}
          </p>
        </template>

        <template #cell(total)="data">
          <p v-b-tooltip.hover class="text-left text-wrap">
            {{ getFormatedAmount(getAmountAfterDiscount(data.item)) }}</p>
        </template>

        <template #cell(status)="data">
          <b-badge v-if="data.item.status.toUpperCase()==='PAID'"
                   variant="success" class="text-left text-wrap">{{ data.item.status.replaceAll('_', ' ') }}
          </b-badge>

          <b-badge v-if="data.item.status.toUpperCase()==='DRAFT'"
                   variant="warning" class="text-left text-wrap">{{ data.item.status.replaceAll('_', ' ') }}
          </b-badge>

          <b-badge v-if="data.item.status.toUpperCase()==='READY_TO_PAY'"
                   variant="info" class="text-left text-wrap">{{ data.item.status.replaceAll('_', ' ') }}
          </b-badge>

          <b-badge v-if="data.item.status.toUpperCase()==='GENERATED '"
                   variant="dark" class="text-left text-wrap">{{ data.item.status.replaceAll('_', ' ') }}
          </b-badge>

          <b-badge v-if="data.item.status.toUpperCase()==='OVERDUE'"
                   variant="danger" class="text-left text-wrap">{{ data.item.status.replaceAll('_', ' ') }}
          </b-badge>

          <b-badge v-if="data.item.status.toUpperCase()==='REFUNDED'"
                   variant="info" class="text-left text-wrap">{{ data.item.status.replaceAll('_', ' ') }}
          </b-badge>

        </template>

        <template #cell(actions)="data">
          <b-button v-if="data.item.status.toUpperCase()==='PAID'"
                    title="Download"
                    variant="primary"
                    @click="downloadInvoice(data.item)">
            <div v-html="downloadIcon"></div>
          </b-button>
          <b-button size="sm" v-if="data.item.status.toUpperCase()==='OVERDUE'"
                    variant="warning"
                    @click="payInvoice(data.item)">
            PAY
          </b-button>
        </template>

      </b-table>
      <b-row>
        <b-col>
          <p>Showing {{ (this.pageNumber * this.pageSize) + 1 }} to {{ getEndNumber() }} of {{ this.totalRows }}
            entries </p>
        </b-col>
        <b-col class="overflow-auto">
          <b-pagination class="pagination pagination-rounded justify-content-end mb-2 mt-2" v-model="currentPage"
                        :total-rows="totalRows" :per-page="pageSize"
                        @page-click="onPageChange" align="end">
          </b-pagination>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import moment from "moment/moment";
import axios from "axios";

export default {
  name: 'SearchScreen',
  components: {},
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    token: {
      type: String
    },
    apiKey: {
      type: String
    },
    currencySymbol: {
      type: String
    }
  },
  data() {
    return {
      showLoader: false,
      showOverlay: false,
      showHiddenGrid: false,
      title: 'Invoice History',
      items: [],
      fields: [
        {key: 'tsid', label: 'Invoice #'},
        {key: 'name', label: 'Plan'},
        {key: 'invoice_date', label: 'Invoice Date'},
        {key: 'due_date', label: 'Due Date'},
        {key: 'paid_date', label: 'Paid Date'},
        {key: 'payment_method', label: 'Payment Method'},
        {key: 'total', label: 'Total'},
        {key: 'status', label: 'Status'},
        {key: 'actions', label: 'Actions'},
      ],
      pageSize: 10,
      pageNumber: 0,
      currentPage: 1,
      totalRows: 0,
      isBusy: false,
    }
  },
  watch: {},
  methods: {
    getDateInStr(date) {
      if (!date) {
        return ''
      }
      return moment(new Date(date)).format(process.env.VUE_APP_DATE_DEBUG ? 'DD-MMM-YYYY HH:mm' : 'MMM DD, YYYY')
    },
    async getAllInvoices() {
      this.showOverlay = true
      try {
        const headers = {
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        let invoiceStatus = 'PAID,OVERDUE,REFUNDED'
        let url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/customer/${this.customerIdentifier}/invoices?page_size=${this.pageSize}&page_number=${this.pageNumber}&status=${invoiceStatus}`
        const {data} = await axios.get(url, {headers: headers})
        if (data.status) {
          this.items = []
          for (let i = 0; i < data.value.length; i++) {
            this.items.push({...data.value[i], actions: true})
          }
          const pagination = data.pagination
          this.totalRows = pagination.total
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.showOverlay = false
      }
    },
    onPageChange(event, pageNumber) {
      this.pageNumber = pageNumber - 1
      this.getAllInvoices()
    },
    getEndNumber() {
      const first = (this.pageNumber * this.pageSize) + this.pageSize
      return Math.min(this.totalRows, first)
    },
    getPlanName(item) {
      if (item.plan && item.invoice_for.startsWith("PLV-"))
        return item.plan.name
      if (item.addon && item.invoice_for.startsWith("ADV-"))
        return item.addon.name
    },
    getAmountAfterDiscount(item) {
      if (item.transactions.coupon) {
        const discountType = item.transactions.coupon.discount_type
        const discount = item.transactions.coupon.discount
        if(discountType === 'PERCENTAGE') {
          return item.total - (item.total * discount / 100)
        } else {
          return item.total - discount * 100
        }
      }
      return item.total
    },
    getFormatedAmount(amount) {
      return `${this.currencySymbol}${(amount / 100).toFixed(2)}`
    },
    async downloadInvoice(item) {
      this.showOverlay = true
      try {
        let url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/invoice/${item.tsid}/download`
        await this.downloadBill(url, item.tsid);
      } catch (e) {
        console.error(e)
      } finally {
        this.showOverlay = false
      }
    },
    async downloadBill(url, filename) {
      // eslint-disable-next-line no-useless-catch
      try {
        const headers = {
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`,
        }
        const {data} = await axios.get(url, {headers: headers, responseType: "blob",});
        await this.downloadPdfFiles(data, filename);
      } catch (error) {
        throw error;
      }
    },
    async downloadPdfFiles(data, fileName) {
      const href = URL.createObjectURL(data);
      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
    async payInvoice(item) {
      this.showOverlay = true
      try {
        const headers = {
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`,
        }
        let url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/invoice/${item.tsid}/pay`
        const {data} = await axios.post(url, {}, {headers: headers})
        window.location = data.value;
      } catch (e) {
        console.error(e)
        const data = e.response.data
        alert(data?.error?.message)
      } finally {
        this.showOverlay = false
      }
    }
  },
  computed: {
    downloadIcon() {
      return '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"' +
          '     style="width:16px ;fill: rgb(255 255 255);transform: ;msFilter:;">' +
          '    <path d="m12 16 4-5h-3V4h-2v7H8z"></path>' +
          '    <path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path>' +
          '</svg>';
    }
  },
  async mounted() {
    this.showOverlay = true
    await this.getAllInvoices();
    this.showOverlay = false
  }
}
</script>

<style scoped>
.form_card {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  justify-content: left;
  padding: 20px 30px;
  gap: 13px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062);
}

.imgclass {
  width: 15px !important;
}
</style>
